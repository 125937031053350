import React from "react"
import Excerpt from "@/components/Excerpt"

const ListNote = ({ edges }) => (
    <div class="card-grid">
        {edges.map(edge => (
            <Excerpt key={edge.node.fields.slug} edge={edge} />
        ))}
    </div>
)

export default ListNote
