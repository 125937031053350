import styled from "styled-components"

export const Pill = styled.span`
    display: inline-block;
    border-radius: var(--radius-round);
    box-shadow: var(--shadow-5);
    padding-inline: var(--size-3);
    color: var(--purple-3);
    --link-visited: var(--purple-3);
    --link: var(--purple-1);
    background-color: var(--gray-10);
`
