import React from "react"
import { graphql } from "gatsby"
import ListNote from "@/components/ListNote"
import { HERO_TITLE, HERO_SUBTITLE } from "../../config"
import Layout from "@/components/Layout"

const IndexPage = ({ data, pageContext }) => {
    const { edges } = data.allMdx

    return (
        <Layout>
            <h1>{HERO_TITLE}</h1>
            <h2>{HERO_SUBTITLE}</h2>
            <ListNote edges={edges} />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query IndexTemplateQuery {
        allMdx(
            filter: {
                frontmatter: { tags: { in: "main" }, publish: { ne: false } }
                fileAbsolutePath: { regex: "/vault/" }
            }
            limit: 10
            sort: { order: DESC, fields: fields___date }
        ) {
            edges {
                node {
                    ...postList
                }
            }
        }
    }
`
