import React from "react"
import { Link } from "gatsby"
import Maturity from "@/components/Maturity"
const Excerpt = ({ edge }) => (
    <article>
        <header>
            <Link to={`/${edge.node.fields.slug}`}>
                <h2>{edge.node.fields.title}</h2>
            </Link>
            <time>{edge.node.fields.date}</time>
        </header>
        <p>{edge.node.frontmatter.description || edge.node.excerpt}</p>
        <footer>
            <Maturity stage={edge.node.fields.stage} />
        </footer>
    </article>
)

export default Excerpt
